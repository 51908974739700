import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Logout from '@mui/icons-material/Logout'
import { ChevronDown } from 'react-feather'

import { destroySession } from 'core/services/storage'
import { User } from '../header/model/user'
import * as Styled from './styles'

export default function Profile({ username, role }: User) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const exitSession = () => {
    destroySession()
    handleClose()
  }
  return (
    <React.Fragment>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        onClick={handleClick}
      >
        <IconButton
          onClick={handleClick}
          size='small'
          disabled
          sx={{ paddingRight: '16px' }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              background: '#e0e0e0'
            }}
          >
            <Styled.AvatarName>
              {username?.at(0)?.toUpperCase()}
            </Styled.AvatarName>
          </Avatar>
        </IconButton>
        <Styled.Container>
          <Styled.Name>Olá, {username}</Styled.Name>
          <Styled.OcuppationName>{role?.name}</Styled.OcuppationName>
        </Styled.Container>
        <Styled.ContainerIcon className={open ? 'rotate-icon' : ''}>
          <ChevronDown size={20}></ChevronDown>
        </Styled.ContainerIcon>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={exitSession} sx={{ minWidth: '12.3rem' }}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
