import { Breadcrumbs } from '@mui/material'
import Link from '../link'

interface BreadCrumbConfig {
  config: {
    label: string
    isLink: boolean
    to: string | null
  }[]
}

export default function BreadCrumb(breadCrumbsConfig: BreadCrumbConfig) {
  const breadcrumbs = breadCrumbsConfig.config.map((bc, index) =>
    bc.isLink ? (
      <Link key={index} to={bc.to ?? ''}>
        {bc.label}
      </Link>
    ) : (
      <span key={index}>{bc.label}</span>
    )
  )

  return (
    <Breadcrumbs separator='>' aria-label='breadcrumb'>
      {breadcrumbs}
    </Breadcrumbs>
  )
}
