import styled from 'styled-components'

export const Header = styled.header`
  position: fixed;
  z-index: ${({ theme }) => theme.layers.overlay};
  width: 100%;
  right: 0;
  top: 0;
  height: 88px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    list-style: none;
    display: flex;

    li {
      margin-left: 65px;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        position: relative;
        top: initial;
      }
    }
  }
  .nav-btn {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .no-items {
    top: 9px;
    padding-top: 11px;
    padding-bottom: 14px;
  }
  .pb-btn {
    padding: 0.77rem 1.5rem 5px 1.5rem;
  }

  @media only screen and (${({ theme }) => theme.device.laptop}) {
    ul {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      z-index: 10;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.5rem;
      background-color: #ffffff;
      transition: 1s;
      transform: translateY(-100vh);

      li {
        margin-left: 0px;

        &:last-child {
          position: absolute;
          top: 100px;
        }
      }

      &.responsive-nav {
        transform: none;
      }
    }
    .nav-btn {
      z-index: 11;
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 1;
  padding-inline: 88px;
  .logotipo {
    color: ${({ theme }) => theme.colors.dark};
  }
  @media only screen and (${({ theme }) => theme.device.tablet}) {
    padding-inline: 35px;
  }
`
