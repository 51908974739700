export const icons = {
  message: require('core/assets/icons/message.svg'),
  message_unabled: require('core/assets/icons/message-unabled.svg'),
  close: require('core/assets/icons/close.svg'),
  calendar: require('core/assets/icons/calendar.svg'),
  use_term: require('core/assets/icons/use_term.svg'),
  exam: require('core/assets/icons/exam.svg'),
  file: require('core/assets/icons/file.svg'),
  justice: require('core/assets/icons/justice.svg'),
  down_arrow: require('core/assets/icons/down-arrow.svg'),
  arrow_left_large: require('core/assets/icons/arrow-left-large.svg'),
  check: require('core/assets/icons/check.svg'),
  check_circle: require('core/assets/icons/check-circle.svg'),
  facebook: require('core/assets/icons/facebook.svg'),
  linkedin: require('core/assets/icons/linkedin.svg'),
  twitter: require('core/assets/icons/twitter.svg'),
  email: require('core/assets/icons/email.svg'),
  whatsapp: require('core/assets/icons/whatsapp.svg'),
  download: require('core/assets/icons/download.svg'),
  plus: require('core/assets/icons/plus.svg'),
  plus_white: require('core/assets/icons/plus_white.svg'),
  camera: require('core/assets/icons/camera.svg'),
  trash: require('core/assets/icons/trash.svg'),
  alert: require('core/assets/icons/alert.svg'),
  alert_enabled: require('core/assets/icons/alert-enabled.svg'),
  eye: require('core/assets/icons/eye.svg'),
  eye_off: require('core/assets/icons/eye-off.svg'),
  valid: require('core/assets/icons/valid.svg'),
  invalid: require('core/assets/icons/invalid.svg'),
  to_late: require('core/assets/icons/to_late.svg'),
  open_status: require('core/assets/icons/open_status.svg'),
  arrow_action: require('core/assets/icons/arrow_action.svg'),
  arrow_down_select: require('core/assets/icons/arrow_down_select.svg'),
  msg_error: require('core/assets/icons/msg_error.svg')
}
