import BasicMenu from 'core/components/dropdown'
import * as Styled from './styles'
import { PropsButton } from 'core/components/dropdown/ButtonMenu'
import { useEffect, useRef, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'
import Profile from '../profile'
import apiFetch from 'core/services/api'
import { CategoryModel } from '../../services/models/category'
import { setUserSession } from 'core/services/storage/localStorage/setSession'
import { getUserSession } from 'core/services/storage'
import { notify } from 'core/services/notification'
import { User } from './model/user'
import { logos } from 'core/assets'
import { useLocation } from 'react-router-dom'

export default function Header() {
  const navRef = useRef<HTMLUListElement>(null)
  const [navBar, setNavBar] = useState<
    { name: string; hasIcon: boolean; to: string }[] | null
  >(null)
  const [load, setLoad] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)

  const location = useLocation()

  function isActiveRoute(route: string): boolean {
    return location.pathname.includes(route)
  }

  const classNameLink = (route: string, additionalClasses: string) => {
    return `${additionalClasses} ${isActiveRoute(route) ? 'active-route' : ''}`
  }

  useEffect(() => {
    setLoad(true)
    async function getCategories() {
      const response = await apiFetch({
        method: 'get',
        url: 'categories',
        params: { _limit: 5, _sort: 'updatedAt' }
      })
      if (response.error) {
        notify.error(
          'Não foi possivel carregar as informações de categorias da página, tente novamente mais tarde.'
        )
        setLoad(false)
        return
      }

      const categoriesService = response.success as CategoryModel
      const postsResponse = await apiFetch({
        method: 'get',
        url: 'posts',
        params: { _limit: 5, _sort: 'updatedAt' }
      })
      const postId = postsResponse.success?.data.find(
        (item: { attributes: { title: string } }) =>
          item.attributes.title === 'Saiba mais sobre nós'
      )?.id
      categoriesService.data.push(
        categoriesService.data.splice(
          categoriesService.data.findIndex(
            (item) => item.attributes.title === 'Saiba mais sobre nós'
          ),
          1
        )[0]
      )
      const navBarMapped = categoriesService.data.map((category) => {
        if (
          category.attributes.title === 'Saiba mais sobre nós' &&
          category.id &&
          postId
        ) {
          return {
            name: category.attributes.title,
            hasIcon: false,
            to: `/noticias/${category.id}/post/${postId}`
          }
        } else {
          return {
            name: category.attributes.title,
            hasIcon: false,
            to: `/noticias/${category.id}`
          }
        }
      })

      setNavBar(navBarMapped)
      setLoad(false)
    }
    async function getUser() {
      const response = await apiFetch({
        method: 'get',
        url: 'users/me',
        params: { populate: 'role' }
      })
      if (response.error) {
        notify.error('Tivemos um problema para obter o seu usuário.')
        return
      }
      const user = {
        userId: response.success.id,
        userName: response.success.username,
        profileId: response.success.role.id,
        profileName: response.success.role.name
      }
      setUserSession(user)
      setUser(response.success)
    }
    getCategories()
    getUser()
  }, [])

  const showNavBar = () => {
    navRef.current?.classList.toggle('responsive-nav')
  }

  const userData = getUserSession()

  const navOptions = [
    { name: 'Novo chamado', hasIcon: true, to: '/chamados/novo' },
    { name: 'Meus chamados', hasIcon: false, to: '/chamados/meus-chamados' }
  ]

  if ([1, 2].includes(userData.profileId)) {
    navOptions.push({
      name: 'Minhas tutorias',
      hasIcon: false,
      to: '/chamados/minhas-tutorias'
    })
  }

  if ([1].includes(userData.profileId)) {
    navOptions.push({
      name: 'Administrativo',
      hasIcon: false,
      to: '/chamados/admin'
    })
  }

  const navUserService: PropsButton = {
    listItems: navOptions,
    name: 'Chamados',
    className: classNameLink('/chamados', 'text-small pb-btn')
  }

  return (
    <Styled.Header>
      <Styled.Content>
        <img src={logos.logo_rm.default} alt='Logotipo' />
        <ul ref={navRef}>
          <li>
            <BasicMenu
              name='Início'
              className={classNameLink('/home', 'text-small no-items')}
              listItems={null}
              to='/home'
            />
          </li>
          {(navBar || load) && (
            <li>
              <BasicMenu
                name='Conteúdo'
                className={classNameLink('/noticias', 'text-small pb-btn')}
                listItems={navBar}
                isLoading={load}
                to='/noticias'
              />
            </li>
          )}
          <li>
            <BasicMenu
              to='/chamados'
              className={classNameLink('/chamados', 'pb-btn')}
              {...navUserService}
            />
          </li>
          <li>
            <Profile
              username={user?.username?.split(' ')[0]}
              role={user?.role}
            />
          </li>
        </ul>
        <IconButton
          color='primary'
          className='nav-btn'
          aria-label='show menu'
          onClick={showNavBar}
        >
          <MenuIcon />
        </IconButton>
      </Styled.Content>
    </Styled.Header>
  )
}
