import { Outlet } from 'react-router-dom'
import { logos } from 'core/assets'
import * as Styled from './styles'

export function BaseAuthLayout() {
  return (
    <Styled.Container>
      <Styled.ContainerLogo>
        <img src={logos.logo_rm.default} alt='Logotipo' />
      </Styled.ContainerLogo>
      <Styled.ContainerCardAuth>
        <Outlet />
      </Styled.ContainerCardAuth>
    </Styled.Container>
  )
}
