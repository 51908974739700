import { Outlet } from 'react-router-dom'
import * as Styled from './styles'
import Header from 'core/components/header'
import Footer from 'core/components/footer'

export function DashBoardLayout() {
  return (
    <Styled.Container>
      <Header />
      <Styled.ContentContainer>
        <Outlet />
      </Styled.ContentContainer>
      <Footer />
    </Styled.Container>
  )
}
