import * as Styled from './styles'

export interface PropsLink {
  to: string
  className?: string
  block?: boolean
  disabled?: boolean
  children: React.ReactNode
}

function Link({ disabled, children, className, ...props }: PropsLink) {
  return (
    <div className={className}>
      <Styled.Link type='link' {...props} disabled={disabled}>
        {children}
      </Styled.Link>
    </div>
  )
}

export default Link
