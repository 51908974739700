import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  margin-right: ${({ theme }) => theme.spacings.xs};
`
export const OcuppationName = styled.p`
  text-align: start;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  font-weight: ${({ theme }) => theme.font.normal};
`
export const Name = styled.h3`
  text-align: start;
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.dark};
`

export const AvatarName = styled.h3`
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  font-weight: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.dark};
  opacity: 0.64;
`

export const ContainerIcon = styled.span`
  & svg {
    transition: 0.3s all ease;
    transform: rotate(0deg);
  }
  &.rotate-icon {
    transition: 0.3s all ease;
    & svg {
      transform: rotate(180deg);
    }
  }
`
