import { lazy, Suspense } from 'react'
import Auth from 'core/routes/guards/Auth'

import { DashBoardLayout } from 'core/layouts/Dashboard'
import NewsDetail from './pages/newsDetail'

const Home = lazy(() => import('./pages/home'))
const Articles = lazy(() => import('./pages/noticias'))
const Terms = lazy(() => import('./pages/terms'))

export default [
  {
    path: 'home',
    element: <Auth component={DashBoardLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        )
      },
      {
        path: 'termos',
        element: (
          <Suspense fallback={<>...</>}>
            <Terms terms />
          </Suspense>
        )
      },
      {
        path: 'politicas',
        element: (
          <Suspense fallback={<>...</>}>
            <Terms terms={false} />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'noticias',
    element: <Auth component={DashBoardLayout} />,
    children: [
      {
        path: ':categoryId',
        element: (
          <Suspense fallback={<>...</>}>
            <Articles />
          </Suspense>
        )
      },
      {
        path: ':categoryId/post/:id',
        element: (
          <Suspense fallback={<>...</>}>
            <NewsDetail />
          </Suspense>
        )
      }
    ]
  }
]
