import styled from 'styled-components'

export const MenuLink = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #292929;
  text-decoration: none;
`
