import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

interface PropsLink {
  disabled?: boolean
  block?: boolean
}

export const Link = styled(LinkRouter)<PropsLink>`
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: ${({ theme }) => theme.font.normal};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
