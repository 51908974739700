import { UserSession } from '../model/user-session'
import typeStorage from './types'

export function setSession(key: string, value: any): boolean {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (error) {
    return false
  }
}

export function setUserSession(value: any): boolean {
  try {
    return setSession('@user-data', value)
  } catch (error) {
    return false
  }
}

export function setAuthSession(value: any): boolean {
  try {
    return setSession('@sirio-session', value)
  } catch (error) {
    return false
  }
}
