import { SvgIcon } from '@mui/material'

export default function DownArrow(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.202148 0.868308C0.462659 0.608121 0.884769 0.608384 1.14496 0.868896L6.02022 5.75024L10.8819 0.888602C11.1422 0.628252 11.5643 0.628252 11.8247 0.888602C12.085 1.14895 12.085 1.57106 11.8247 1.83141L6.49133 7.16474C6.36625 7.28982 6.1966 7.36006 6.01972 7.36001C5.84283 7.35995 5.67322 7.2896 5.54823 7.16445L0.20156 1.81112C-0.058627 1.55061 -0.0583639 1.1285 0.202148 0.868308Z'
        fill='#292929'
      />
    </SvgIcon>
  )
}
