import styled from 'styled-components'

interface PropsButton {
  disabled?: boolean
  block?: boolean
  listItems: { name: string; hasIcon: boolean }[] | null
}

export const ButtonMenu = styled.button<PropsButton>`
  border: 0;
  padding: ${(props) =>
    !props.block ? '0.77rem 1.5rem 0.77rem 1.5rem' : '0.77rem 1.5rem'};
  box-shadow: none;
  cursor: pointer;
  position: relative;
  outline: none;
  background: #ffffff;
  width: ${(props) => props.block && '100%'};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  line-height: inherit;
  &[aria-expanded='true'] ~ span:before,
  &[aria-expanded='true'] ~ span:after,
  &:hover ~ span:before,
  &:hover ~ span:after,
  &.active-route ~ span:before,
  &.active-route ~ span:after,
  &:active ~ span:before,
  &:active ~ span:after {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    width: calc(50% + 8px);
  }
  & ~ span {
    position: relative;
    display: block;
    width: 100%;
    &:before,
    &:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: -1px;
      position: absolute;
      background-color: transparent;
      transition: 0.2s ease all;
    }
    &:before {
      left: ${(props) => (props.listItems ? 'calc(50% + 0px)' : '50%')};
    }
    &:after {
      right: ${(props) => (props.listItems ? 'calc(50% - 0px)' : '50%')};
    }
  }

  & span.title-button {
    position: relative;
    bottom: 7px;
  }

  & svg {
    position: relative;
    left: 18px;
    transition: 0.3s all;
  }
  &[aria-expanded='true'] svg {
    transform: rotate(180deg);
    & path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`
