import { UserSession } from '../model/user-session'

export function getSession(key: string) {
  try {
    const session = window.localStorage.getItem(key)
    if (session) {
      return JSON.parse(session)
    }
    return {} as UserSession
  } catch (error) {
    return {} as UserSession
  }
}

export function getUserSession() {
  try {
    return getSession('@user-data')
    return {} as UserSession
  } catch (error) {
    return {} as UserSession
  }
}

export function getAuthSession() {
  try {
    return getSession('@sirio-session')
    return {} as UserSession
  } catch (error) {
    return {} as UserSession
  }
}
