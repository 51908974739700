import Link from '../link'
import * as Styled from './styles'
import { logos } from 'core/assets'

export default function Footer() {
  const getYear = () => new Date().getFullYear()
  return (
    <Styled.Footer>
      <Styled.Content>
        <Styled.ContainerLogo>
          <img src={logos.sirio.default} alt='Logo sirio' />
          <img src={logos.cnj.default} alt='Logo cnj' />
          <img src={logos.proadi.default} alt='Logo proadi' />
          <img src={logos.governo.default} alt='Logo governo' />
        </Styled.ContainerLogo>
        <Styled.CopyRight>
          <Link to='/home/termos'>Termos</Link>
          <Link to='/home/politicas'>Política de Privacidade</Link>
          <span className='text-muted text-xxsmall'>
            &#169; {getYear()} Rede Magistrados
          </span>
        </Styled.CopyRight>
      </Styled.Content>
    </Styled.Footer>
  )
}
