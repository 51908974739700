import { lazy, Suspense } from 'react'

import { BaseAuthLayout } from 'core/layouts/BaseAuth'
import NoAuth from 'core/routes/guards/NoAuth'
import { BaseLayout } from 'core/layouts/Base'

const Login = lazy(() => import('./pages/login'))
const ForgotPassword = lazy(() => import('./pages/forgotPassword'))
const ResetPassword = lazy(() => import('./pages/resetPassword'))
const Terms = lazy(() => import('./pages/terms'))

export default [
  {
    path: '',
    element: <NoAuth component={BaseAuthLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Login />
          </Suspense>
        )
      },
      {
        path: 'forgot-password',
        element: (
          <Suspense fallback={<>...</>}>
            <ForgotPassword />
          </Suspense>
        )
      },
      {
        path: 'reset-password/:code',
        element: (
          <Suspense fallback={<>...</>}>
            <ResetPassword />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'termos',
    element: <NoAuth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Terms terms />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'politicas',
    element: <NoAuth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Terms terms={false} />
          </Suspense>
        )
      }
    ]
  }
]
