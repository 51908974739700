import React from 'react'
import { Outlet } from 'react-router-dom'
import { logos } from 'core/assets'

import * as Styled from './styles'

export function BaseLayout() {
  return (
    <React.Fragment>
      <Styled.ContainerLogo>
        <img src={logos.logo_rm.default} alt='Logotipo' />
      </Styled.ContainerLogo>
      <Outlet />
    </React.Fragment>
  )
}
