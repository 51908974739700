import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 88px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 0px ${({ theme }) => theme.colors.shadow};
  .logotipo {
    color: ${({ theme }) => theme.colors.dark};
    padding-left: ${({ theme }) => theme.spacings.xs};
  }
  img {
    margin-left: ${({ theme }) => theme.spacings.sm};
  }
`
