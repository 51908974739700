import { getAuthSession } from 'core/services/storage'
import { handleResponse } from './handlers/handleResponse'
import { handlerParams } from './handlers/handlerParams'

type UseFetchProps = {
  method: 'get' | 'post' | 'put'
  url: string
  body?: any
  formData?: boolean
  params?: object
}

function authHeader() {
  const session = getAuthSession()
  if (session?.token) return { Authorization: `Bearer ${session?.token}` }
  return
}

export default function apiFetch({
  method,
  url,
  formData,
  params,
  body
}: UseFetchProps) {
  let requestOptions: RequestInit = {
    method,
    headers: {}
  }
  if (!formData) {
    requestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    }
  }
  if (body) requestOptions.body = JSON.stringify(body)
  if (formData) requestOptions.body = body

  return fetch(
    handlerParams(`${process.env.REACT_APP_URL}${url}`, params),
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      return { error, success: false }
    })
}
