import { lazy, Suspense } from 'react'
import Auth from 'core/routes/guards/Auth'

import { UserServiceLayout } from 'core/layouts/UserServiceLayout'

const UserService = lazy(() => import('./pages/userService'))
const AdminService = lazy(() => import('./pages/adminService'))
const NewUserService = lazy(() => import('./pages/newUserService'))
const UserServiceDetail = lazy(() => import('./pages/userServiceDetail'))

export default [
  {
    path: 'chamados',
    element: <Auth component={UserServiceLayout} />,
    children: [
      {
        path: 'meus-chamados',
        element: (
          <Suspense fallback={<>...</>}>
            <UserService
              call
              title='Meus chamados'
              subtitle='Visualize os chamados solicitados por você na listagem abaixo.'
            />
          </Suspense>
        )
      },
      {
        path: 'minhas-tutorias',
        element: (
          <Suspense fallback={<>...</>}>
            <UserService
              call={false}
              title='Minhas tutorias'
              subtitle='Visualize os chamados de suas tutorias.'
            />
          </Suspense>
        )
      },
      {
        path: 'admin',
        element: (
          <Suspense fallback={<>...</>}>
            <AdminService
              call={false}
              title='Administrativo'
              subtitle='Visualize todos os chamados e direcione para o  tutor responsável.'
            />
          </Suspense>
        )
      },
      {
        path: 'novo',
        element: (
          <Suspense fallback={<>...</>}>
            <NewUserService />
          </Suspense>
        )
      },
      {
        path: 'detalhe-chamado/:id',
        element: (
          <Suspense fallback={<>...</>}>
            <UserServiceDetail />
          </Suspense>
        )
      },
      {
        path: 'admin/detalhe-chamado/:id',
        element: (
          <Suspense fallback={<>...</>}>
            <UserServiceDetail admin />
          </Suspense>
        )
      }
    ]
  }
]
