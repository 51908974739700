import { css } from 'styled-components'

export default css`
  .text-white {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.white};
  }
  .text-primary {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.primary};
  }

  .text-light {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.light};
  }

  .text-secondary {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.secondary};
  }

  .text-muted {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.medium};
  }

  .text-xxsmall {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  }

  .text-xsmall {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }

  .text-small {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  .text-medium {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }

  .text-large {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.large};
  }

  .text-xlarge {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }

  .text-xxlarge {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
  }

  .text-huge {
    word-break: break-word;
    font-size: ${({ theme }) => theme.font.sizes.huge};
  }
`
