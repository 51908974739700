import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 88px;

  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    padding-inline: ${({ theme }) => theme.spacings.xxxs};
  }
  @media only screen and (${({ theme }) => theme.device.tablet}) {
    padding-inline: ${({ theme }) => theme.spacings.sm};
  }
`

export const ContentContainer = styled.div`
  min-height: 800px;
`
