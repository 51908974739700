import styled from 'styled-components'

export const Footer = styled.footer`
  position: relative;
  z-index: ${({ theme }) => theme.layers.base};
  width: calc(100% + 176px);
  right: 88px;
  padding-inline: 88px;
  margin-top: 5.625rem;
  height: ${({ theme }) => theme.spacings.huge};
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.shadow};
  display: flex;
  align-items: center;

  @media only screen and (${({ theme }) => theme.device.laptop}) {
  }

  @media only screen and (${({ theme }) => theme.device.tablet}) {
    width: calc(100% + 70px);
    right: 35px;
    padding-inline: 35px;
  }

  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    height: ${({ theme }) => theme.spacings.giant};
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 1;
  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings.xs};
  }
`
export const ContainerLogo = styled.div`
  display: flex;
  img {
    margin-right: ${({ theme }) => theme.spacings.xs};
    height: 1.6375rem;
  }
  @media ${({ theme }) => theme.device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin-top: ${({ theme }) => theme.spacings.xs};
    }
  }
`
export const CopyRight = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xxxs};
  align-items: center;
  justify-content: end;
  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    justify-content: center;
  }
`
