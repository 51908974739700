import styled from 'styled-components'

interface ImageBanner {
  url: string | null
  loading: boolean
}

export const Banner = styled.div<ImageBanner>`
  width: calc(100% + 176px);
  right: 88px;
  height: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacings.xs};
  background-image: url(${(props) => (props.loading ? '' : props.url)});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  @media only screen and (${({ theme }) => theme.device.laptop}) {
    width: calc(100% + 176px);
    right: 88px;
  }
  @media only screen and (${({ theme }) => theme.device.tablet}) {
    padding: 0 44px 0 44px;
    width: calc(100% + 70px);
    right: 35px;
  }
`
export const BreadCrumbContainer = styled.div`
  margin-top: 88px;
  height: 99px;
  display: flex;
  align-items: center;
  @media only screen and (${({ theme }) => theme.device.tablet}) {
    height: 45px;
  }
`
export const Content = styled.div`
  padding-inline: 100px;

  .container-share {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    height: 2rem;

    @media only screen and (${({ theme }) => theme.device.tablet}) {
      flex-direction: column;
      gap: ${({ theme }) => theme.spacings.xxs};
    }
    .content-social-media {
      display: flex;
      gap: ${({ theme }) => theme.spacings.xxs};
      margin-left: ${({ theme }) => theme.spacings.xxxs};
    }
  }
  @media only screen and (${({ theme }) => theme.device.tablet}) {
    padding-inline: 20px;
    p {
      font-size: 14px;
      word-break: break-word;
    }
  }
`
