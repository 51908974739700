import { createGlobalStyle, css } from 'styled-components'

import {
  ArchivoRegular,
  ArchivoBold,
  InterRegular,
  InterBold
} from 'core/assets/fonts'
import margin from './margin'
import padding from './padding'
import text from './text'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inter Regular'), local('Inter-Regular'),
        url(${InterRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: medium;
    font-weight: 500;
    font-display: swap;
    src: local('Inter Medium'), local('Inter-Medium'),
        url(${InterRegular}) format('woff2');

  }
  
  @font-face {
    font-family: 'Inter';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: local('Inter Bold'), local('Inter-Bold'),
        url(${InterBold}) format('woff2');
  }

  @font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Archivo Regular'), local('Arquivo-Regular'),
        url(${ArchivoRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Archivo';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: local('Archivo Bold'), local('Arquivo-Bold'),
        url(${ArchivoBold}) format('woff2');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    padding-right: 0 !important;
    overflow: auto !important;

    @media(max-width: 1080px) {
        font-size: 93.75%; // 15px
    }

    @media(max-width: 975px) {
        font-size: 87.5%; // 14px
    }
}

  ${({ theme }) => css`
    body {
      font-family: ${theme.font.familyInter};
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: ${theme.font.normal};
    }

    button,
    input,
    textarea {
      font-family: ${theme.font.familyInter};
    }

    .notification {
      font-family: ${theme.font.familyInter};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.medium};
    }
  `}

 

  ${margin}

  ${padding}
  
  ${text}  
`
