import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ButtonMenu, { PropsButton } from './ButtonMenu'
import { ListItemIcon, ListItemText } from '@mui/material'
import * as Styled from './styles'
import DownArrow from 'core/assets/icons/down-arrow'
import { useNavigate } from 'react-router-dom'
import { icons } from 'core/assets'

interface Route {
  to?: string
}

export default function BasicMenu(props: PropsButton & Route) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (!props.listItems) navigate(props.to || '/home')
  }
  const handleClose = (event?: any, reason?: string) => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.listItems) setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <div>
        <ButtonMenu
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onMouseEnter={handleOpen}
          onClick={handleClick}
          className={props.className}
          name={props.name}
          isLoading={props.isLoading}
          listItems={props.listItems}
        />
        <span></span>
      </div>
      {props.listItems && (
        <Menu
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
              borderRadius: '8px',
              marginTop: '12px',
              marginLeft: '-10px',
              minWidth: '272px'
            }
          }}
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-menu'
          }}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {props.listItems?.map((item, index) => (
            <MenuItem
              sx={{
                padding: 0
              }}
              key={index}
              href={props?.to || '/home'}
            >
              <Styled.MenuLink href={item.to}>
                {item.hasIcon && (
                  <ListItemIcon>
                    <img src={icons.plus.default} alt='Plus' />
                  </ListItemIcon>
                )}
                <h4 className='notification'>{item.name}</h4>
              </Styled.MenuLink>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
}
