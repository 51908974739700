import Spinner from 'core/components/spinner'
import DownArrow from 'core/assets/icons/down-arrow'

import * as Styled from './styles'

export interface PropsButton {
  name: string
  color?: 'primary'
  block?: boolean
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  listItems: { name: string; hasIcon: boolean; to: string }[] | null
}

function ButtonMenu({
  name,
  disabled,
  color,
  className,
  isLoading,
  listItems,
  ...props
}: PropsButton) {
  let classNameBtn = className + ' ' || ''
  classNameBtn = classNameBtn + color || ''

  return (
    <>
      <Styled.ButtonMenu
        className={classNameBtn}
        name={name}
        disabled={disabled || isLoading}
        listItems={listItems}
        {...props}
      >
        {isLoading ? (
          <Spinner text='Please wait...' size={18} color='primary' />
        ) : (
          <span className='title-button'>{name}</span>
        )}
        {listItems && <DownArrow viewBox='-6 -8 24 24' />}
      </Styled.ButtonMenu>
    </>
  )
}

export default ButtonMenu
