import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'

import Breadcrumb from 'core/components/breadcrumb'

import { notify } from 'core/services/notification'
import { Posts } from 'views/blog/model/category'
import apiFetch from 'core/services/api'

import * as Styled from './styles'

export default function Post() {
  const [load, setLoad] = useState<boolean>(false)
  const [post, setPost] = useState<Posts>()
  const [category, setCategory] = useState<{ title: string; icon: string }>({
    title: '',
    icon: ''
  })
  const { categoryId, id } = useParams()

  async function getNews() {
    setLoad(true)
    const response = await apiFetch({
      method: 'get',
      url: `posts/${id}`,
      params: { populate: 'category,thumb' }
    })
    if (response.error) {
      notify.error(
        'Não foi possível carregar as notícias, tente novamente mais tarde.'
      )
      setLoad(false)
      return
    }
    setLoad(false)
    setCategory(response.success.data.attributes.category.data.attributes)
    setPost(response.success.data)
  }

  useEffect(() => {
    getNews()
  }, [])

  const breadcrumbs = {
    config: [
      {
        label: 'Início',
        isLink: true,
        to: '/'
      },
      {
        label: 'Conteúdo',
        isLink: true,
        to: `/noticias/${categoryId}`
      },
      {
        label: category.title,
        isLink: true,
        to: `/noticias/${categoryId}`
      },
      {
        label: 'Detalhes da Notícia',
        isLink: false,
        to: null
      }
    ]
  }

  function formatDate() {
    const date = new Date(post?.attributes.publishedAt || new Date())
    return date.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  return (
    <>
      <Styled.BreadCrumbContainer>
        <Breadcrumb {...breadcrumbs} />
      </Styled.BreadCrumbContainer>
      <Styled.Banner
        loading={load}
        url={
          post?.attributes?.thumb?.data?.attributes?.url
            ? `${post?.attributes?.thumb?.data?.attributes?.url}`
            : ''
        }
      >
        <Skeleton
          variant='rectangular'
          sx={{ zIndex: 10, height: '100%', minHeight: '300px' }}
        />
      </Styled.Banner>

      <Styled.Content>
        {load ? (
          <>
            <Skeleton variant='text' width={'33%'} />
            <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <br />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <br />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '0.825rem' }} />
            <br />
          </>
        ) : (
          <>
            <p className='text-muted text-xsmall mb-xxs'>{formatDate()}</p>
            <h1 className='text-large mb-xs'>{post?.attributes.title}</h1>
            <div
              className='text-small'
              dangerouslySetInnerHTML={{
                __html: post?.attributes.content || ''
              }}
            ></div>
          </>
        )}
      </Styled.Content>
    </>
  )
}
